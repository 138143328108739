<template>
  <div>
    <v-ace-editor
        v-model:value="localConfigString"
        lang="json"
        theme="chrome"
        :min-lines="30"
        :max-lines="500"
    />
  </div>
</template>

<script>
import {VAceEditor} from "vue3-ace-editor";

export default {
  name: "JsonConfig",
  data() {
    return {
      localConfigString: JSON.stringify(this.object, null, 2)
    }
  },
  components: { VAceEditor },
  watch: {
    localConfigString() {
      this.$emit("updated", JSON.parse(this.localConfigString));
    }
  },
  props: [ "object" ]
}
</script>

<style scoped>

</style>