import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import {createAuth0, authGuard} from "@auth0/auth0-vue";
import GlobalContainer from "@/components/GlobalContainer.vue";

import ace from 'ace-builds';
import modeJsonUrl from 'ace-builds/src-noconflict/mode-json?url';
ace.config.setModuleUrl('ace/mode/json', modeJsonUrl);
import themeChromeUrl from 'ace-builds/src-noconflict/theme-chrome?url';
ace.config.setModuleUrl('ace/theme/chrome', themeChromeUrl);

const routes = [
    { path: '/job/:id', component: GlobalContainer, beforeEnter: authGuard }
]

const router = createRouter({
    routes,
    history: createWebHistory()
})

const app = createApp(App);

app.use(router);

app.use(createAuth0({
    domain: process.env.VUE_APP_AUTH_DOMAIN,
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    authorizationParams: {
        redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URL,
        audience: process.env.VUE_APP_AUTH_AUDIENCE
    }
}));

app.mount("#app");