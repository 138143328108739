<template>
  <div>
    <n-form>
      <n-grid :span="24" :x-gap="12">
        <n-form-item-gi :span="12" label="Crawl Title">
          <n-input v-model:value="localObject.dbConfig.name" />
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Client">
          <n-select :options="clientListOptions" />
        </n-form-item-gi>
        <n-form-item-gi :span="24" label="Base URL" >
          <n-input v-model:value="localObject.crawlerConfig.baseURL" />
        </n-form-item-gi>
        <n-form-item-gi :span="24" label="User Agent">
          <n-input v-model:value="localObject.crawlerConfig.userAgent" />
        </n-form-item-gi>
        <n-form-item-gi :span="6">
          <n-checkbox v-model:checked="localObject.crawlerConfig.autoCanonicaliseParameters">Auto-Canonicalise Parameters</n-checkbox>
        </n-form-item-gi>
        <n-form-item-gi :span="6">
          <n-checkbox v-model:checked="localObject.crawlerConfig.recordAnchors">Record Anchors</n-checkbox>
        </n-form-item-gi>
        <n-form-item-gi :span="6">
          <n-checkbox v-model:checked="localObject.crawlerConfig.respectRobots">Respect Robots</n-checkbox>
        </n-form-item-gi>
        <n-form-item-gi :span="6">
          <n-checkbox v-model:checked="localObject.crawlerConfig.handleCookies">Handle Cookies</n-checkbox>
        </n-form-item-gi>
        <n-form-item-gi :span="16" label="Slack End Point">
          <n-input v-model:value="localObject.crawlerConfig.slackEndpoint" />
        </n-form-item-gi>
        <n-form-item-gi :span="6" label="Notify After URLs">
          <n-input v-model:value="localObject.crawlerConfig.notifyAfterURLs" :disabled="!localObject.slackEndpoint"/>
        </n-form-item-gi>
        <n-form-item-gi :span="2" label="Max Urls">
          <n-input v-model:value="localObject.crawlerConfig.maxUrls"/>
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Rate Limit">
          <n-input v-model:value="rateLimitVal" />
          <n-select v-model:value="rateLimitUnit" :options="[
              { value: 'per second', label: 'per second' },
              { value: 'per minute', label: 'per minute' },
              { value: 'per hour', label: 'per hour' }
          ]"/>
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Ramp Up Time">
          <n-input v-model:value="rampUpTimeVal" />
          <n-select v-model:value="rampUpTimeUnit" :options="[
              { value: 'seconds', label: 'seconds' },
              { value: 'minutes', label: 'minutes' },
              { value: 'hours', label: 'hours' }
          ]" />
        </n-form-item-gi>
        <n-form-item-gi :span="24" label="Link Regular Expressions">
          <n-input v-model:value="localObject.crawlerConfig.linkRegex" />
        </n-form-item-gi>
        <n-form-item-gi :span="24" label="XML Sitemaps (Comma Separated)">
          <n-input v-model:value="siteMapString" />
        </n-form-item-gi>
        <n-form-item-gi :span="24" label="HTTP Headers">
          <div class="aceContainer">
            <v-ace-editor
                    v-model:value="httpHeaderJson"
                    lang="json"
                    theme="chrome"
                    :min-lines="12"
                    :max-lines="500"
                    :options="{
                        tabSize: 2
                     }"
                    width="100%"
                    height="100%"
            />
          </div>
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Page Groups">
          <div class="aceContainer">
            <v-ace-editor
                      v-model:value="pageGroupJson"
                      lang="json"
                      theme="chrome"
                      :min-lines="12"
                      :max-lines="500"
                      :options="{
                        tabSize: 2
                      }"
                      width="100%"
                      height="100%"
            />
          </div>
        </n-form-item-gi>
        <n-form-item-gi :span="12" label="Link Groups">
            <div class="aceContainer">
                <v-ace-editor
                        v-model:value="linkGroupJson"
                        lang="json"
                        theme="chrome"
                        :min-lines="12"
                        :max-lines="500"
                        :options="{
                          tabSize: 2
                      }"
                      width="100%"
                      height="100%"
                />
            </div>
        </n-form-item-gi>
      </n-grid>
    </n-form>
  </div>
</template>

<script>
import { NGrid, NForm, NFormItemGi, NInput, NSelect, NCheckbox } from "naive-ui";
import _ from "lodash";
import {VAceEditor} from "vue3-ace-editor";
import clientList from "@/resources/clientList.json";

export default {
  name: "UIConfig",
  data() {
    return {
      localObject: _.cloneDeep(this.object),
      rateLimitVal: this.object.crawlerConfig.rateLimit.split(" ")[0],
      rateLimitUnit: this.object.crawlerConfig.rateLimit.substring(this.object.crawlerConfig.rateLimit.indexOf(" ")).trim(),
      rampUpTimeVal: this.object.crawlerConfig.rampUpTime.split(" ")[0],
      rampUpTimeUnit: this.object.crawlerConfig.rampUpTime.split(" ")[1],
      httpHeaderJson: JSON.stringify(this.object.crawlerConfig.httpHeaders, null, 2),
      pageGroupJson: JSON.stringify(this.object.crawlerConfig.pageGroups, null, 2),
      linkGroupJson: JSON.stringify(this.object.crawlerConfig.linkGroups, null, 2),
      siteMapString: this.object.crawlerConfig.siteMapLinks.join(",")
    }
  },
  computed: {
    clientListOptions() {
      return _.map(clientList, obj => {
        return {
          label: obj.clientName,
          value: obj.clientUUID
        }
      })
    }
  },
  methods: {
    calcCrawlsPerSecond() {
      const rlVal = Number.parseInt(this.rateLimitVal);

      if (this.rateLimitUnit === "per second") {
        return rlVal;
      } else if (this.rateLimitUnit === "per minute") {
        return Math.ceil(rlVal / 60);
      } else if (this.rateLimitUnit === "per hour") {
        return Math.ceil(rlVal / 60 / 60);
      }

      return null;
    }
  },
  watch: {
    httpHeaderJson() {
      this.localObject.crawlerConfig.httpHeaders = JSON.parse(this.httpHeaderJson);
    },
    pageGroupJson() {
      this.localObject.crawlerConfig.pageGroups = JSON.parse(this.pageGroupJson);
    },
    linkGroupJson() {
      this.localObject.crawlerConfig.linkGroups = JSON.parse(this.linkGroupJson);
    },
    rateLimitVal() {
      this.localObject.crawlerConfig.rateLimit = this.rateLimitVal + " " + this.rateLimitUnit;

      this.localObject.crawlerConfig.numThreads = this.calcCrawlsPerSecond() * 2;
    },
    rateLimitUnit() {
      this.localObject.crawlerConfig.rateLimit = this.rateLimitVal + " " + this.rateLimitUnit;

      this.localObject.crawlerConfig.numThreads = this.calcCrawlsPerSecond() * 2;
    },
    rampUpTimeVal() {
      this.localObject.crawlerConfig.rampUpTime = this.rampUpTimeVal + " " + this.rampUpTimeUnit;
    },
    rampUpTimeUnit() {
      this.localObject.crawlerConfig.rampUpTime = this.rampUpTimeVal + " " + this.rampUpTimeUnit;
    },
    siteMapString() {
      if (this.siteMapString.trim().length == 0) {
        this.localObject.crawlerConfig.siteMapLinks = [];
      } else {
        this.localObject.crawlerConfig.siteMapLinks = _.map(this.siteMapString.split(","), url => url.trim());
      }
    },
    localObject: {
      handler() {
        this.$emit("updated", this.localObject); // Omit attributes which are empty strings.
      },
      deep: true
    }
  },
  components: {
      VAceEditor, NGrid, NForm, NFormItemGi, NInput, NSelect, NCheckbox
  },
  props: [ "object" ]
}
</script>

<style scoped>
  .aceContainer {
      width: 100%;
      height: 150px;
      overflow: auto;
      position: relative;
  }
</style>