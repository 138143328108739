<template>
  <n-tabs>
    <n-tab-pane name="easyConfig" tab="Easy Config">
      <UIConfig :object="configObj" @updated="changeMade"/>
    </n-tab-pane>
    <n-tab-pane name="jsonConfig" tab="JSON Config">
      <JsonConfig :object="configObj" @updated="changeMade"/>
    </n-tab-pane>
  </n-tabs>
  <div style="display: flex; justify-content: flex-end; gap: 10px;">
    <n-button type="primary" @click="save">Save</n-button>
    <n-button type="default" @click="cancel">Cancel</n-button>
  </div>
</template>

<script>
import UIConfig from "@/components/UIConfig";
import {NTabs, NTabPane, NButton} from "naive-ui";
import axios from 'axios';
import JsonConfig from "@/components/JsonConfig";

export default {
  name: 'GlobalContainer',
  data() {
    return {
      configObj: {
        crawlerConfig: {
          userAgent: "Mozilla/5.0 (compatible; IMWT-Bot/1.0; +https://inmarketingwetrust.com.au/imwt-bot/)",
          rateLimit: "1 per second",
          numThreads: 2,
          rampUpTime: "0 seconds",
          autoCanonicaliseParameters: true,
          recordAnchors: false,
          respectRobots: true,
          handleCookies: false,
          linkGroups: [],
          pageGroups: [],
          siteMapLinks: [],
          flushSize: 10_000
        },
        dbConfig: {
          name: "",
        }
      }
    }
  },
  mounted() {
    // Load existing config from API if previously saved
    this.$auth0.getAccessTokenSilently().then((token) => {
      axios.get(process.env.VUE_APP_JOB_API_URL + '/job/config/' + this.$route.params.id, { headers: { Authorization: 'Bearer ' + token } })
          .then((response) => {
            if (response.data.config !== '') {
              this.changeMade(JSON.parse(response.data.config));
              this.key++; // Forces rendering of the component after data is set
            }
          }).catch(() => {}); // TODO: Handle API errors
    });
  },
  methods: {
    changeMade(changeObj) {
      this.configObj = changeObj;
    },
    save() {
      // Save the config against the jobId, providing a file name and the config JSON
      this.$auth0.getAccessTokenSilently().then((token) => {
        axios.post(process.env.VUE_APP_JOB_API_URL + '/job/config',
            { jobId: this.$route.params.id, fileName: 'config.json', configJson: JSON.stringify(this.configObj) },
            { headers: { Authorization: 'Bearer ' + token } })
            .then(() => location.href = process.env.VUE_APP_JOB_UI_URL + '/config/' + this.$route.params.id)
            .catch(() => {});
      })
    },
    cancel() {
      location.href = process.env.VUE_APP_JOB_UI_URL + '/config/' + this.$route.params.id;
    }
  },
  components: {
    JsonConfig,
    UIConfig, NTabs, NTabPane, NButton
  }
}
</script>

<style>
</style>